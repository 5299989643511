<template>
  <!--待发起对账结算详情-->
  <div>
    <div>
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div>
          <span style="font-size: 24px;font-weight: 500;color: #707070;">对账单XXX，XXX司机，2023-05，待对账</span>
        </div>
        <div>
          <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
        </div>
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="救援城市:">
                    <el-input
                      clearable
                      v-model="where.facilitator_name"
                      placeholder="请输入救援城市"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="救援项目:">
                    <el-select clearable v-model="where.value" placeholder="请选择">
                      <el-option label="综合" value="1"/>
                      <el-option label="综合1" value="2"/>
                      <el-option label="综合2" value="3"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="订单状态:">
                    <el-select clearable v-model="where.value" placeholder="请选择">
                      <el-option label="综合" value="1"/>
                      <el-option label="综合1" value="2"/>
                      <el-option label="综合2" value="3"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="对账状态">
                    <el-select clearable v-model="where.value" placeholder="请选择">
                      <el-option label="综合" value="1"/>
                      <el-option label="综合1" value="2"/>
                      <el-option label="综合2" value="3"/>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="24" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">批量确认</span>
                </el-button>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 30px;">订单数量：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">2</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">合同价：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">2</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">整体补收款：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">2</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">应收款：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">2000</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">已收款：</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;">0</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">对账资料：</span>
                <span style="font-size: 18px;font-weight: 700;color: #fea837;cursor: pointer;" @click="openZiliao(null)">点击查看</span>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >

              <!--自定义列显示-->
              <template v-slot:status= "{ scope }">
                <div v-if="scope.row.status === 0">
                  <span style="width: 8px;height: 8px;background: #FF9E1C;border-radius: 50%;display: inline-block;margin-right: 10px;"></span>
                  <span>待完成</span>
                </div>
                <div v-else>
                  <span style="width: 8px;height: 8px;background: #25EB61;border-radius: 50%;display: inline-block;margin-right: 10px;"></span>
                  <span>已完成</span>
                </div>
              </template>

              <template v-slot:zhaopian= "{ scope }">
                <img src="@/assets/images/home/yunli/duihao.png" alt="" v-if="scope.row.status === 0" style="width: 24px;height: 24px;">
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" v-else style="width: 24px;height: 24px;">
              </template>

              <template v-slot:gongdan= "{ scope }">
                <img src="@/assets/images/home/yunli/duihao.png" alt="" v-if="scope.row.status === 0" style="width: 24px;height: 24px;">
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" v-else style="width: 24px;height: 24px;">
              </template>

              <template v-slot:guiji= "{ scope }">
                <img src="@/assets/images/home/yunli/duihao.png" alt="" v-if="scope.row.status === 0" style="width: 24px;height: 24px;">
                <img src="@/assets/images/home/yunli/cuohao.png" alt="" v-else style="width: 24px;height: 24px;">
              </template>

              <template v-slot:duizhang= "{ scope }">
                <span v-if="scope.row.status === 0">未开始</span>
                <span v-if="scope.row.status === 1">客户待对账</span>
                <span v-if="scope.row.status === 2">客户已确认</span>
                <span v-else>对账不符</span>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
                  <el-link slot="reference" :underline="false" type="success" style="margin-right: 10px;">确认</el-link>
                </el-popconfirm>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>

      <!--对账资料弹窗-->
      <reconciliationInformation-edit :data="ziliaocurrent" :visible.sync="showziliao"/>

    </div>

  </div>
</template>

<script>
//引入对账资料弹窗组件
import ReconciliationInformationEdit from './components/reconciliationInformation-edit.vue'

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi,
  set_statusApi
} from '@/api/custom'

export default {
  // 组件生效
  components: {
    PackageSortElement,
    ReconciliationInformationEdit,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        facilitator_name:'',
        authentication_status:'全部',
        value1:'',
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "对账单号",
          // type: "index",
          // width: "70"
          prop: "id",
          fixed:'left',
          isShow: true
        },
        {
          label: "救援城市",
          prop: "id",
          isShow: true
        },
        {
          label: "故障车牌",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "救援类型",
          prop: "id",
          isShow: true
        },
        {
          label: "客户",
          prop: "id",
          isShow: true
        },
        {
          label: "订单状态",
          prop: "status",
          slot: 'status',
          isShow: true
        },
        {
          label: "去程",
          prop: "id",
          isShow: true
        },
        {
          label: "背程",
          prop: "id",
          isShow: true
        },
        {
          label: "增值服务项",
          prop: "id",
          isShow: true
        },
        {
          label: "照片完整",
          prop: "status",
          slot: 'zhaopian',
          isShow: true
        },
        {
          label: "工单完整",
          prop: "status",
          slot: 'gongdan',
          isShow: true
        },
        {
          label: "轨迹完整",
          prop: "status",
          slot: 'guiji',
          isShow: true
        },
        {
          label: "计价公式",
          prop: "id",
          isShow: true
        },
        {
          label: "合同价",
          prop: "status",
          isShow: true
        },
        {
          label: "对账状态",
          prop: "status",
          slot: 'duizhang',
          isShow: true
        },
        {
          label: "下单时间",
          prop: "status",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          fixed:'right',
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //发起对账弹窗
      current:null,
      showEdit:false,

      //不符原因
      bufucurrent:null,
      showbufu:false,

      //对账资料
      ziliaocurrent:null,
      showziliao:false,

      //对账记录
      jilucurrent:null,
      showjilu:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      if (this.where.authentication_status === '全部'){
        this.where.authentication_status = ' ';
      }
      if (this.where.status === '全部'){
        this.where.status = ' ';
      }
      getfacilitatorApi(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
        this.where.authentication_status = '全部';
        this.where.status = '全部';
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        facilitator_name:'',
        authentication_status:' ',
        status:' ',
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //点击返回按钮
    returnPage(){
      this.$emit('showDetails',false);
    },

    //点击发起对账
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    //点击不符
    openBufu(row){
      this.bufucurrent = row;
      this.showbufu = true;
    },

    //对账资料
    openZiliao(row){
      this.ziliaocurrent = row;
      this.showziliao = true;
    },

    //对账记录
    openJilu(row){
      this.jilucurrent = row;
      this.showjilu = true;
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
